import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  useTheme,
  CircularProgress,
  IconButton,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Grid,
  Stack,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { tokens } from "../../assets/theme";
import {
  getCustomers,
  getForecastData,
  getSalesPersons,
  getItems,
  getDateKeys,
  getFinancialBudgets,
  getAllCustomers,
  getAllItems,
} from "./hooks/fetchers";
import ForecastDataGrid from "./components/forecastDataGrid";
import messages from "./components/messages";
import { FormattedMessage } from "react-intl";
import Paper from "@mui/material/Paper";
import { experimentalStyled as styled } from "@mui/material/styles";
import AxionSelect from "../../components/controls/AxionSelect";
import Popup from "../../components/Popup";
import AddForecast from "./components/forecastAddDialog";
import Button from "../../components/controls/Button";
import AddIcon from "@mui/icons-material/Add";
import AxionControls from "../../components/controls/AxionControls";
import StatBox from "../../components/UI/StatBox";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

const ForecastData = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [selectedCustomer, setSelectedCustomer] = React.useState("");
  const [selectedCustomerName, setSelectedCustomerName] = React.useState("");
  const [selectedSalesPerson, setSelectedSalesPerson] = React.useState("");
  const [selectedItem, setSelectedItem] = React.useState("");
  const [selectedItemName, setSelectedItemName] = React.useState("");
  const [selectedDateKey, setSelectedDateKey] = React.useState("");
  const [selectedMonth, setSelectedMonth] = React.useState("");
  const [selectedCustomerRating, setSelectedCustomerRating] =
    React.useState("");
  const [selectedTest, setSelectedTest] = React.useState("");
  const [totalFinancialBudget, setTotalFinancialBudget] = React.useState(0);
  const [totalBudget, setTotalBudget] = React.useState(0);
  const [totalForecast, setTotalForecast] = React.useState(0);
  const [totalOrderStock, setTotalOrderStock] = React.useState(0);
  const [openPopup, setOpenPopup] = useState(false);

  const transformDate = (items) => {
    let renamed = items.map(({ key, date }) => ({
      id: key,
      title: date.substring(0, 7),
    }));

    return renamed;
  };

  const transformItem = (items) => {
    let renamed = items.map((item) => ({
      id: item.key,
      label: item.code + " - " + item.name,
    }));
    return renamed;
  };
  const transformCustomer = (customers) => {
    let renamed = customers.map((customer) => ({
      id: customer.key,
      label: customer.code + " - " + customer.name,
    }));
    return renamed;
  };
  const handleCustomerChange = (value) => {
    setSelectedCustomer(value.id);
    setSelectedCustomerName(value);
  };

  const handleSalesPersonChange = (event) => {
    setSelectedSalesPerson(event.target.value);
  };

  const handleItemChange = (value) => {
    setSelectedItem(value.id);
    setSelectedItemName(value);
  };

  const handleSelectedTestChange = (event) => {
    console.log(event.target);
    handleSelectedTestChange(event.target.value);
  };

  const handleDateKeyChange = (event) => {
    console.log(event);
    console.log(financialBudgets.data);
    setSelectedMonth(
      dateKeys.data
        .find((datekey) => datekey.key === event.target.value)
        ?.date.substring(0, 7)
    );
    setSelectedDateKey(event.target.value);
    console.log(financialBudgets.data);
    setTotalFinancialBudget(
      financialBudgets.data.find(
        (datekey) => datekey.dateKey === event.target.value
      )?.financialBudgetNetValue
    );
  };

  const handleCustomerRatingChange = (event) => {
    setSelectedCustomerRating(event.target.value);
  };

  const handleTotalForecastChange = (totalForecastChange) => {
    setTotalForecast(
      (prevTotalForecast) => prevTotalForecast + totalForecastChange
    );
  };

  const handleAddForecastClick = () => {
    setOpenPopup(true);
  };

  const forecasts = useQuery(
    ["forecasts"],
    () =>
      getForecastData(
        selectedCustomer,
        selectedSalesPerson,
        selectedItem,
        selectedDateKey,
        selectedCustomerRating
      ),
    {
      staleTime: Infinity,
    }
  );

  const customers = useQuery(["customers"], () => getCustomers(), {
    staleTime: Infinity,
  });

  const allCustomers = useQuery(["AllCustomers"], () => getAllCustomers(), {
    staleTime: Infinity,
  });

  const salesPersons = useQuery(["salespersons"], () => getSalesPersons(), {
    staleTime: Infinity,
  });

  const items = useQuery(["items"], () => getItems(), {
    staleTime: Infinity,
  });

  const allItems = useQuery(["allItems"], () => getAllItems(), {
    staleTime: Infinity,
  });

  const dateKeys = useQuery(["datekeys"], () => getDateKeys(), {
    staleTime: Infinity,
  });

  const financialBudgets = useQuery(
    ["financialbudgets"],
    () => getFinancialBudgets(selectedDateKey),
    {
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    forecasts.refetch();
  }, [
    selectedCustomer,
    selectedSalesPerson,
    selectedItem,
    selectedDateKey,
    selectedCustomerRating,
  ]);
  // useEffect(() => { financialBudgets.refetch(); }, [selectedDateKey]);

  useEffect(() => {
    if (forecasts.isFetched) {
      setTotalBudget(
        forecasts.data.reduce((n, { budgetNetValue }) => n + budgetNetValue, 0)
      );
      setTotalForecast(
        forecasts.data.reduce((n, forecast) => {
          if (
            forecast.correctedForecastNetValue === undefined ||
            forecast.correctedForecastNetValue === null
          ) {
            return n + forecast.mlForecastNetValue;
          } else {
            return n + forecast.correctedForecastNetValue;
          }
        }, 0)
      );
      setTotalOrderStock(
        forecasts.data.reduce(
          (n, { orderStockNetValue }) => n + orderStockNetValue,
          0
        )
      );
      //setTotalFinancialBudget(financialBudgets.data.reduce((n, {financialBudgetNetValue}) => n + financialBudgetNetValue, 0));
    }
  }, [forecasts.data]);

  useEffect(() => {
    if (financialBudgets.isFetched) {
      setTotalFinancialBudget(
        financialBudgets.data.reduce(
          (n, { financialBudgetNetValue }) => n + financialBudgetNetValue,
          0
        )
      );
    }
  }, [financialBudgets.data]);

  if (
    forecasts.isLoading ||
    customers.isLoading ||
    salesPersons.isLoading ||
    items.isLoading ||
    dateKeys.isLoading ||
    financialBudgets.isLoading ||
    allItems.isLoading
  ) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      <Box
        ml="20px"
        mt="10px"
        mr="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={
              totalFinancialBudget.toLocaleString("sv-SE", {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              }) + " kr"
            }
            subtitle={<FormattedMessage {...messages.financialBudget} />}
            icon={
              <MonetizationOnIcon sx={{ color: "#799c4a", fontSize: "25px" }} />
            }
            progress="none"
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={
              totalBudget.toLocaleString("sv-SE", {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              }) + " kr"
            }
            subtitle={<FormattedMessage {...messages.salesBudget} />}
            icon={
              <MonetizationOnIcon sx={{ color: "#799c4a", fontSize: "25px" }} />
            }
            progress="none"
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={
              totalForecast.toLocaleString("sv-SE", {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              }) + " kr"
            }
            subtitle={<FormattedMessage {...messages.forecastAndCorrections} />}
            icon={
              <MonetizationOnIcon sx={{ color: "#799c4a", fontSize: "25px" }} />
            }
            progress={(totalForecast / totalBudget).toLocaleString("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
            increase={` ${((totalForecast / totalBudget) * 100).toLocaleString(
              "en-US",
              { maximumFractionDigits: 2, minimumFractionDigits: 2 }
            )} %`}
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={
              totalOrderStock.toLocaleString("sv-SE", {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              }) + " kr"
            }
            subtitle={<FormattedMessage {...messages.sumOrderStockNetValue} />}
            icon={
              <MonetizationOnIcon sx={{ color: "#799c4a", fontSize: "25px" }} />
            }
            progress={(totalOrderStock / totalBudget).toLocaleString("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
            increase={` ${(
              (totalOrderStock / totalBudget) *
              100
            ).toLocaleString("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })} %`}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          p: 1,
          margin: "20px",
          bgcolor: colors.primary[600],
          borderRadius: 1,
        }}
      >
        <Stack direction="row" width="100%" marginLeft={2} spacing={2}>
        <FormControl
          sx={{
            minWidth: 100,
            marginRight: 2,
            borderColor: "#799c4a !important",
            border: "1px solid",
            borderRadius: "4px",
            "& .MuiInputBase-root": {
              bgcolor: "white",
              color: "black",
              borderColor: "#799c4a !important",
              border: "1px solid",
            },
            "& .MuiInputBase-input": {
              color: "black", // Ensures the input text is also black
            },
            "& .MuiInputLabel-root": {
              color: "black", // Ensures the input text is also black
            },
            "& .MuiSvgIcon-root": {
              color: "black", // Changing the dropdown arrow to black
            },
          }}
        >
          <AxionControls.AxionSelect
            size="small"
            value={selectedDateKey}
            label={<FormattedMessage {...messages.dateColumnLabel} />}
            onChange={handleDateKeyChange}
            options={transformDate(dateKeys.data)}
          />
        </FormControl>
        <FormControl sx={{ minWidth: 300, marginRight: 2 }}>
          <AxionControls.AxionAutoComplete
            label={<FormattedMessage {...messages.itemNameColumnLabel} />}
            value={selectedItemName}
            onChange={(event, value) => {
              console.log(value);
              if (value === null) {
                setSelectedItem("");
                setSelectedItemName("");
              } else {
                handleItemChange(value);
              }
            }}
            //options={items.data.map(({key, code}) => ({key: key, title: code }))}
            options={transformItem(items.data)}
            optionLabel="name"
            optionKey="key"
          />
        </FormControl>

        <FormControl sx={{ minWidth: 300, marginRight: 2 }}>
          <AxionControls.AxionAutoComplete
            label={<FormattedMessage {...messages.customerNameColumnLabel} />}
            value={selectedCustomerName}
            onChange={(event, value) => {
              console.log(value);
              if (value === null) {
                setSelectedCustomer("");
                setSelectedCustomerName("");
              } else {
                handleCustomerChange(value);
              }
            }}
            options={transformCustomer(customers.data)}
            optionLabel="name"
            optionKey="key"
          />
        </FormControl>

        <FormControl
          sx={{
            minWidth: 200,
            marginRight: 2,
            borderColor: "#799c4a !important",
            border: "1px solid",
            borderRadius: "4px",
            "& .MuiInputBase-root": {
              bgcolor: "white",
              color: "black",
              borderColor: "#799c4a !important",
              border: "1px solid",
            },
            "& .MuiInputBase-input": {
              color: "black", // Ensures the input text is also black
            },
            "& .MuiInputLabel-root": {
              color: "black", // Ensures the input text is also black
            },
            "& .MuiSvgIcon-root": {
              color: "black", // Changing the dropdown arrow to black
            },
          }}
        >
          <AxionControls.AxionSelect
            size="small"
            showNoneOption={true}
            value={selectedCustomerRating}
            label={<FormattedMessage {...messages.customerRatingColumnLabel} />}
            onChange={handleCustomerRatingChange}
            options={[
              { id: 1, title: <FormattedMessage {...messages.goldLabel} /> },
              { id: 2, title: <FormattedMessage {...messages.silverLabel} /> },
              { id: 3, title: <FormattedMessage {...messages.bronzeLabel} /> },
            ]}
          />
        </FormControl>
        <FormControl
          sx={{
            minWidth: 250,
            marginRight: 2,
            borderColor: "#799c4a !important",
            border: "1px solid",
            borderRadius: "4px",
            "& .MuiInputBase-root": {
              bgcolor: "white",
              color: "black",
              borderColor: "#799c4a !important",
              border: "1px solid",
            },
            "& .MuiInputBase-input": {
              color: "black", // Ensures the input text is also black
            },
            "& .MuiInputLabel-root": {
              color: "black", // Ensures the input text is also black
            },
            "& .MuiSvgIcon-root": {
              color: "black", // Changing the dropdown arrow to black
            },
          }}
        >
          <AxionControls.AxionSelect
            size="small"
            showNoneOption={true}
            value={selectedSalesPerson}
            label={
              <FormattedMessage {...messages.salespersonNameColumnLabel} />
            }
            onChange={handleSalesPersonChange}
            options={salesPersons.data.map(({ key, name }) => ({
              id: key,
              title: name,
            }))}
          />
        </FormControl>
     
        <Button
                    text=" Ny rad"
                    startIcon={<AddIcon />}
                    variant="outlined"
                    onClick={handleAddForecastClick}
                    display="flex"
                    
                    justifyContent="end"
             
                   
                    ml={2}
                    sx={{
                    "&:hover": {
                        backgroundColor: colors.dashbordGreen[700],
                    },
                    backgroundColor: colors.dashbordGreen[600],
                    color: "#ffffff",
                    // position: "absolute",
                    // right: "20px",
                 
                    display: "flex",
                    justifyContent: "end",
                    }}
                />
     </Stack>
      </Box>

      <ForecastDataGrid
        forecasts={forecasts.data}
        isFetching={forecasts.isFetching}
        handleTotalForecastChange={handleTotalForecastChange}
      />
      <Popup
        title="Lägg till prognosrad"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        maxWidht="xs"
      >
        <AddForecast
          date={selectedMonth}
          dateKey={selectedDateKey}
          customers={allCustomers.data}
          items={allItems.data}
          forecasts={forecasts}
          setOpenPopup={setOpenPopup}
        />
      </Popup>
    </>
  );
};

export default ForecastData;
