import * as React from "react";
import { useTheme, Paper, Box, Toolbar, IconButton, Grid } from "@mui/material";
import { tokens } from "../../../assets/theme";
import { DataGrid, GridToolbar, gridClasses } from '@mui/x-data-grid';
import { CircularProgress } from "@mui/material";
import { useMsal, useAccount } from "@azure/msal-react";
import CustomerRatingIcon from "./customerRatingIcon";
import { experimentalStyled as styled } from "@mui/material/styles";

//LANGUAGE
import messages from "./messages";
import { FormattedMessage } from "react-intl";
import { updateForcastQuantity } from "../hooks/fetchers";

const setCustomerABCColor = ({ id, value, field }) => {
  let color = '';
  let name = '';
  if(value === 1) {
    color = '#FFD700';
    name = 'A';
  } else if(value === 2) {
    color = '#C0C0C0';
    name = 'B'
  }
  else if(value === 3) {
    color = '#CD7F32';
    name = 'C';
  }
  return <CustomerRatingIcon color={color} name={name} /> 
};

const columns = [
    { 
      field: "date", 
      headerName: <FormattedMessage {...messages.dateColumnLabel} />, 
      width: 80, 
      renderCell: ({ id, value, field }) => { return value.substring(0,7) }
    },
    { 
      field: "customerABC", 
      headerName: '', 
      renderCell: setCustomerABCColor,
      width: 50
    },
    { 
      field: "customerName", 
      headerName: <FormattedMessage {...messages.customerNameColumnLabel} />, 
      width: 150
    },
    { 
      field: "salesPersonName", 
      headerName: <FormattedMessage {...messages.salespersonNameColumnLabel} />, 
      width: 150, 
    },
    { 
      field: "itemCode", 
      headerName: <FormattedMessage {...messages.itemCodeColumnLabel} />, 
      width: 100, 
    },
    { 
      field: "itemName",
      headerName: <FormattedMessage {...messages.itemNameColumnLabel} />, 
      width: 200, 
    },
    { 
      field: "correctedForecastQty", 
      headerName: <FormattedMessage {...messages.correctedForecastQtyColumnLabel} />, 
      width: 120, 
      editable: true, 
      type: 'number', 
      headerAlign: 'left',
      
      valueFormatter: (value) => {
        if (value == null) {
          return '';
        }
        return value.toLocaleString("sv-SE", { maximumFractionDigits: 0, minimumFractionDigits: 0 });
      },
    },
    { 
      field: "correctedForecastNetValue", 
      headerName: <FormattedMessage {...messages.correctedForecastNetVolumeColumnLabel} />, 
      width: 120, 
      type: 'number', 
      valueFormatter: (value) => {
        if (value == null) {
          return '';
        }
        return value.toLocaleString("sv-SE", { maximumFractionDigits: 0, minimumFractionDigits: 0 }) + ' kr';
      },
    },
    { 
      field: "mlForecastQty", 
      headerName: <FormattedMessage {...messages.suggestedForecastQtyColumnLabel} />, 
      width: 150, 
      type: 'number', 
      valueFormatter: (value) => {
        if (value == null) {
          return '';
        }
        return value.toLocaleString("sv-SE", { maximumFractionDigits: 0, minimumFractionDigits: 0 });
      },
    },
    { 
      field: "mlForecastNetValue", 
      headerName: <FormattedMessage {...messages.suggestedForecastNetVolumeColumnLabel} />, 
      width: 150, 
      type: 'number', 
      valueFormatter: (value) => {
        if (value == null) {
          return '';
        }
        return value.toLocaleString("sv-SE", { maximumFractionDigits: 0, minimumFractionDigits: 0 }) + ' kr';
      },
    },
    { 
      field: "budgetQty", 
      headerName: <FormattedMessage {...messages.budgetQtyColumnLabel} />, 
      width: 150, 
      type: 'number', 
      valueFormatter: (value) => {
        if (value == null) {
          return '';
        }
        return value.toLocaleString("sv-SE", { maximumFractionDigits: 0, minimumFractionDigits: 0 });
      },
    },
    { 
      field: "budgetNetValue", 
      headerName: <FormattedMessage {...messages.budgetNetVolumeColumnLabel} />, 
      width: 150, 
      type: 'number', 
      valueFormatter: (value) => {
        if (value == null) {
          return '';
        }
        return value.toLocaleString("sv-SE", { maximumFractionDigits: 0, minimumFractionDigits: 0 }) + ' kr';
      },
    },
    { 
      field: "orderStockQty", 
      headerName: <FormattedMessage {...messages.orderStockQtyColumnLabel} />, 
      width: 150, 
      type: 'number', 
      valueFormatter: (value) => {
        if (value == null) {
          return '';
        }
        return value.toLocaleString("sv-SE", { maximumFractionDigits: 0, minimumFractionDigits: 0 });
      },
    },
    { 
      field: "orderStockNetValue", 
      headerName: <FormattedMessage {...messages.orderStockNetValueColumnLabel} />, 
      width: 150, 
      type: 'number', 
      valueFormatter: (value) => {
        if (value == null) {
          return '';
        }
        return value.toLocaleString("sv-SE", { maximumFractionDigits: 0, minimumFractionDigits: 0 }) + ' kr';
      },
    },
    { 
      field: "r12Qty", 
      headerName: <FormattedMessage {...messages.r12QtyColumnLabel} />, 
      width: 120, 
      type: 'number', 
      valueFormatter: (value) => {
        if (value == null) {
          return '';
        }
        return value.toLocaleString("sv-SE", { maximumFractionDigits: 0, minimumFractionDigits: 0 });
      },
    },
    { 
      field: "r12NetValue", 
      headerName: <FormattedMessage {...messages.r12NetValueColumnLabel} />, 
      width: 120, 
      type: 'number', 
      valueFormatter: (value) => {
        if (value == null) {
          return '';
        }
        return value.toLocaleString("sv-SE", { maximumFractionDigits: 0, minimumFractionDigits: 0 }) + ' kr';
      },
    },
    { 
      field: "itemPrice", 
      headerName: <FormattedMessage {...messages.itemPriceColumnLabel} />, 
      width: 120, 
      type: 'number', 
      valueFormatter: (value) => {
        if (value == null) {
          return '';
        }
        return value.toLocaleString("sv-SE", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ' kr';
      }
    },
    { 
      field: "updatedBy", 
      headerName: <FormattedMessage {...messages.updatedByColumnLabel} />, 
      width: 175,
    },
    { 
      field: "updated", 
      headerName: <FormattedMessage {...messages.updatedColumnLabel} />, 
      width: 175,
    },
  ];

export default function ForecastDataGrid(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { accounts } = useMsal();
    const account = useAccount(accounts[0]);

    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
      salesPersonName: false,

    });

    function updateRow(updatedRow, originalRow)  { 
      if(!updatedRow.correctedForecastQty){
        updatedRow.updatedBy = account.username;
        updatedRow.correctedForecastNetValue = null;
      }
      else {
        updatedRow.updatedBy = account.username;
        updatedRow.correctedForecastNetValue = updatedRow.correctedForecastQty * updatedRow.itemPrice;
      }
      updateForcastQuantity(updatedRow);

      let change = 0;
      if(!originalRow.correctedForecastQty) {
        change = updatedRow.correctedForecastNetValue - originalRow.mlForecastNetValue;
      }
      else if(!updatedRow.correctedForecastQty) {
        change = originalRow.mlForecastNetValue - originalRow.correctedForecastNetValue;
      }
      else {
        change = updatedRow.correctedForecastNetValue - originalRow.correctedForecastNetValue;
      }
      props.handleTotalForecastChange(change);
      return updatedRow;
    }

    const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
      ...theme.typography.body2,
     
      textAlign: "center",
      color: theme.palette.text.secondary,
    }));

    if(props.isLoading) {
      return <Box margin="20px" width="80%">
        <Paper
          sx={{
            position: "relative",
            borderRadius: "4px",
          }}
        >
          <CircularProgress color="secondary" />;
        </Paper>
      </Box>
    }

    return (
<Box margin="20px">
        <Box 
       
        height="64vh"
   
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
            },
            "& .name.column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-cellContent": {
              fontSize: "13px",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: `${colors.gray[100]} !important` ,
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.primary[600],
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.gray[100]} !important`,
            },
            "& .super-app-theme--11": {
              bgcolor: `${colors.redAccent[500]} !important`,
            },
            "& .MuiDataGrid-columnHeader": {
              fontSize: "15px",
              backgroundColor: `${colors.primary[600]} !important`
            },
            [`.${gridClasses.cell}.input`]: {
              backgroundColor: '#799c4a',
              color: '#ffffff',
            },
          }}
        >
          { !props.isFetching && <DataGrid
            rows={props.forecasts}
            getRowId={ (row) => row.automatedForecastKey }
            columns={columns}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            getCellClassName={(params) => {
              if (params.field === 'correctedForecastQty') {
                return 'input';
              }
             
            }}
         
            components={{
              
              Toolbar: GridToolbar
            }}
            processRowUpdate={ (updatedRow, originalRow) => updateRow(updatedRow, originalRow) }
            onProcessRowUpdateError={ (error) => { console.log("error", error) }}
          /> }
          { props.isFetching && <CircularProgress color="secondary" />}
        </Box>
        </Box>
 
  );
}