import React, { useEffect, useState } from 'react'
import { ResponsiveLine } from '@nivo/line'
import { useTheme } from '@mui/material/styles';
import { tokens } from '../../../assets/theme';
import { useQuery } from "@tanstack/react-query";
import { getAcutalSalesPerMonth } from '../hooks/fetchers';
import CircularProgress from '@mui/material/CircularProgress';

import { BarChart } from '@mui/x-charts/BarChart';


function ActualSalesPerMonthDashboard(props) {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const actualSalesPerMonth = useQuery(["actualSalesPerMonth"], () => getAcutalSalesPerMonth(props.itemKey, props.customerKey));
    useEffect(() => { actualSalesPerMonth.refetch(); }, [props.itemKey, props.companyKey]);
    
    if (actualSalesPerMonth.isLoading) {
        return <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}><CircularProgress color="secondary" /></div>;
    }
    // Group data by year
    const dataByYear = actualSalesPerMonth.data.reduce((acc, curr) => {
        const { year, ...rest } = curr;
        if (!acc[year]) {
            acc[year] = [];
        }
        acc[year].push(rest);
        return acc;
    }, {});


    const transformedData = Object.entries(dataByYear).map(([id, data]) => ({ id, data: data.map(item => ({ x: item.month, y: item.netSales })) }));

   console.log(transformedData);
    return (
        <>
            <BarChart
            
      xAxis={[{ scaleType: 'band', data: ['1', '2', '3','4','5','6','7','8','9','10','11','12'] }]}
      series={[{ data: [4, 3, 5] }, { data: [1, 6, 3] }, { data: [2, 5, 6] }]}
      width={700}
      height={500}
    />

{/* <ResponsiveLine
            data={transformedData}
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            xScale={{ type: 'linear' }}
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: true,
                reverse: false
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'transportation',
                legendOffset: 36,
                legendPosition: 'middle',
                truncateTickAt: 0
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'count',
                legendOffset: -40,
                legendPosition: 'middle',
                truncateTickAt: 0,
                
            }}
            
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            enableTouchCrosshair={true}
            useMesh={true}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 1, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        /> */}


        </>
    );
}

export default ActualSalesPerMonthDashboard;