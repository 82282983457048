import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
//import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';

import { inputLabelClasses } from "@mui/material/InputLabel";
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from "@mui/material";


  const RedditTextField = styled((props) => (
    <TextField InputProps={{ disableUnderline: true }} {...props} />
  ))(({ theme }) => ({
    '& .MuiFilledInput-root': {
      overflow: 'hidden',
      borderRadius: 4,
      color: "#000000",
      backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#ffffff',
      border: '2px solid',
      borderColor: theme.palette.mode === 'light' ? '#799c4a' : '#799c4a',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      '&:hover': {
        backgroundColor: '#ffffff',
      },
      '&.Mui-focused': {
        //backgroundColor: 'transparent',
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        borderColor: theme.palette.primary.main,
        color: "#000000"
      },
      '& .MuiInputLabel-root': {
        //backgroundColor: 'transparent',
        color: "#000000"
      },
      
    },
  }));

  export default function AxionSelect(props) {
    const { name, label, value, error = null, onChange, options, showNoneOption = false } = props;

    return (
      <FormControl fullWidth variant="outlined" {...(error && { error: true })}>
        <InputLabel 
          sx={{    
            '&.Mui-focused': { 
              color: "#799c4a"
            },
            '&.MuiFormLabel-root.MuiInputLabel-shrink': {
              top: '15px'
            },
            color: "#799c4a",
          }}>
              {label}
          </InputLabel>
        <MuiSelect variant="filled" size="small" label={label} id={name} name={name} value={value} onChange={onChange}
          MenuProps={{
            PaperProps: {
              sx: {
                bgcolor: 'white',
                color: 'black',
                '& .MuiMenuItem-root': {
                  padding: 1,
                },
              },
            },
          }}
          sx={{
              '& .MuiSvgIcon-root': {
                  color: 'black',  
              },
              '&.MuiFilledInput-root': {
                  overflow: 'hidden',
                  borderRadius: 1,
                  border: '2px solid',
                  borderColor:'#799c4a',
                  background:'#ffffff',
                  color: 'black'
                }
        }}

        >
          {showNoneOption && <MenuItem value="">None</MenuItem>}
          {options.map((item) => (
            <MenuItem 
              key={item.id} 
              value={item.id} 
              sx={{ 
                '&:hover': { backgroundColor: '#cccccc' } 
              }}>
                {item.title}
            </MenuItem>
          ))}
        </MuiSelect>
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
    
  }
  