import { Box, IconButton, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import { ColorModeContext, tokens } from "../assets/theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { borderRadius } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector, useDispatch } from "react-redux";
import { setLanguage, setLanguageFile } from "../context/languageProvider/reducer";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const language = useSelector((state) => state.language.language);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const languages = [
    {
      label: "English",
      src: "https://flagcdn.com/w20/us.png",
      value: "en",
      key: 1,
      file: '../../translations/en.json'
    },
    {
      label: "Svenska",
      src: "https://flagcdn.com/w20/se.png",
      value: "sv",
      key: 2,
      file: '../../translations/sv.json'
    },
  ];
  console.log(language);

  //HANDLERS
  const handleLanguageChange = (event) => {
    dispatch(setLanguage(event.target.value));
    dispatch(setLanguageFile(languages.filter(t => t.value === event.target.value).map(e => e.file)));
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Box display="flex" justifyContent="space-between" ml="20px" mt={1}>
      
      <Box
              display="flex"
              backgroundColor={colors.primary[400]}
              borderRadius="3px"
      >
      <Select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={languages.filter(e => e.value === language).map(e => e.value)}
          name="country"
          //sx={{marginRight: "50px"}}
          onChange={handleLanguageChange}
          size="small"
          inputProps={{
            id: "open-select",
          }}
        >
          {languages.map((option, key) => (
            <MenuItem value={option.value} key={key}>
              <img loading="lazy" src={option.src} alt={option.label} />{"   "} <span>{option.label}</span>
            </MenuItem>
          ))}
        </Select>
      </Box>
      {/* SEARCH BAR */}
      {/* <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
        justifyContent={"left"}
      >
 
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box> */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>

        <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>

        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>

        <IconButton>
          <PersonOutlinedIcon />
        </IconButton>

        <IconButton>
          <SearchOutlinedIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
