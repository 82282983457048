import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, Divider, Button } from "@mui/material";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "../auth/SignInButton";
import { SignOutButton } from "../auth/SignOutButton";
import { useMsal, useAccount } from "@azure/msal-react";

//LANGUAGE
import messages from "./messages";
import { FormattedMessage } from "react-intl";

import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../assets/theme";
import ProfilePicture from "../auth/ProfilePicture";

//ICONS
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.gray[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isAuthenticated = useIsAuthenticated();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const { accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const { instance } = useMsal();
  const [buttonSize, setButtonSize] = useState("");

  const handleLogout = (logoutType) => {
    if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };
  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#a0cb66 !important",
        },
        "& .pro-menu-item.active": {
          color: "#799c4a !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.gray[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="px"
              >
                <img
                  alt="profile-user"
                  width="160px"
                  height="45px"
                  src={`../../assets/Axion_solutions_white.png`}
                />

                <Typography
                  variant="H3"
                  color={colors.gray[100]}
                  sx={{ m: "0px 0px 0px 0px", flex: 1 }}
                >
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <ProfilePicture />
              </Box>
              <Box textAlign="center">
                <Typography
                  color={colors.gray[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0", fontSize: "1.0rem"}}
                >
                  {account.name}
                </Typography>
     
              </Box>
            </Box>
          )}
          <Divider />

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Typography
              variant="h6"
              color={colors.gray[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              <FormattedMessage {...messages.pagesLabel} />
            </Typography>
            <Item
              title={<FormattedMessage {...messages.homeMenuItem} />}
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={<FormattedMessage {...messages.forecastDataMenuItem} />}
              to="/forecastData"
              icon={<AssessmentOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={<FormattedMessage {...messages.dashboardSalesPersonMenuItem} />}
              to="https://powerbi.axjo.com/reports/e2b24d15-eb83-4b45-8a40-46ca6c770c92@eddc61d7-842c-45ea-a061-8bf78180faaa"
              icon={<DashboardOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
          <Divider />
          <Box paddingLeft={isCollapsed ? undefined : "10%"} >
          {!isCollapsed ?
              <Button
              variant="secondary"
              sx={{
                backgroundColor: "#af3f3b",
                marginLeft: 1,
                marginTop: 2,
                width: 200,
              }}
              className="ml-auto"
              onClick={() => handleLogout("redirect")}
            >
              Sign out
            </Button>
      
              :
              <Button
              variant="secondary"
              sx={{
                backgroundColor: "#af3f3b",
                marginLeft: 1,
                marginTop: 2,
                width: 10,
              }}
              className="ml-auto"
              onClick={() => handleLogout("redirect")}
            >
              <PowerSettingsNewIcon />
            </Button>

          }
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
