import React from 'react';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

const CustomerRatingIcon = ({ color, name }) => {
  const style = {
    color: color, // Circle color, passed as a prop
  };

  return <div title={name}><WorkspacePremiumIcon style={style}></WorkspacePremiumIcon></div>;
};

export default CustomerRatingIcon;