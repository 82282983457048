import { defineMessages } from 'react-intl';

export const scope = 'axioninsight.features.forecastData.components';

export default defineMessages({
  dateColumnLabel: {
    id: `${scope}.forecastdatagrid.label.datecolumn`,
    defaultMessage: 'Date',
  },
  itemNameColumnLabel: {
    id: `${scope}.forecastdatagrid.label.itemnamecolumn`,
    defaultMessage: 'Item',
  },
  itemCodeColumnLabel: {
    id: `${scope}.forecastdatagrid.label.itemcodecolumn`,
    defaultMessage: 'Item code',
  },
  customerNameColumnLabel: {
    id: `${scope}.forecastdatagrid.label.customernamecolumn`,
    defaultMessage: 'Customer',
  },
  salespersonNameColumnLabel: {
    id: `${scope}.forecastdatagrid.label.salespersonnamecolumn`,
    defaultMessage: 'Sales person',
  },
  r12QtyColumnLabel: {
    id: `${scope}.forecastdatagrid.label.r12qtycolumn`,
    defaultMessage: 'Rolling 12 months qty',
  },
  r12NetValueColumnLabel: {
    id: `${scope}.forecastdatagrid.label.r12netvaluecolumn`,
    defaultMessage: 'Rolling 12 months net value',
  },
  correctedForecastQtyColumnLabel: {
    id: `${scope}.forecastdatagrid.label.correctedforecastqtycolumn`,
    defaultMessage: 'Corrected forecast qty',
  },
  correctedForecastNetVolumeColumnLabel: {
    id: `${scope}.forecastdatagrid.label.correctedforecastnetvaluecolumn`,
    defaultMessage: 'Corrected forecast net value',
  },
  suggestedForecastQtyColumnLabel: {
    id: `${scope}.forecastdatagrid.label.suggestedforecastqtycolumn`,
    defaultMessage: 'Suggestions forecast qty',
  },
  suggestedForecastNetVolumeColumnLabel: {
    id: `${scope}.forecastdatagrid.label.suggestedforecastnetvaluecolumn`,
    defaultMessage: 'Suggestions forecast net value',
  },
  itemPriceColumnLabel: {
    id: `${scope}.forecastdatagrid.label.itempricecolumn`,
    defaultMessage: 'Item price',
  },
  updatedByColumnLabel: {
    id: `${scope}.forecastdatagrid.label.updatedbycolumn`,
    defaultMessage: 'Updated by',
  },
  updatedColumnLabel: {
    id: `${scope}.forecastdatagrid.label.updatedcolumn`,
    defaultMessage: 'Updated',
  },
  budgetQtyColumnLabel: {
    id: `${scope}.forecastdatagrid.label.budgetqtycolumn`,
    defaultMessage: 'Budget qty',
  },
  budgetNetVolumeColumnLabel: {
    id: `${scope}.forecastdatagrid.label.budgetnetvaluecolumn`,
    defaultMessage: 'Budget net value',
  },
  customerRatingColumnLabel: {
    id: `${scope}.forecastdatagrid.label.customerratingcolumn`,
    defaultMessage: 'Customer rating',
  },
  orderStockQtyColumnLabel: {
    id: `${scope}.forecastdatagrid.label.orderstockqtycolumn`,
    defaultMessage: 'Order stock qty',
  },
  orderStockNetValueColumnLabel: {
    id: `${scope}.forecastdatagrid.label.orderstocknetvaluecolumn`,
    defaultMessage: 'Order stock net value',
  },
  goldLabel: {
    id: `${scope}.forecastdatagrid.label.gold`,
    defaultMessage: 'Gold',
  },
  silverLabel: {
    id: `${scope}.forecastdatagrid.label.silver`,
    defaultMessage: 'Silver',
  },
  bronzeLabel: {
    id: `${scope}.forecastdatagrid.label.bronze`,
    defaultMessage: 'Bronze',
  },
  financialBudget: {
    id: `${scope}.financialbudget`,
    defaultMessage: 'Financial Budget',
  },
  salesBudget: {
    id: `${scope}.salesbudget`,
    defaultMessage: 'Sales Budget',
  },
  forecastAndCorrections: {
    id: `${scope}.forecastandcorrections`,
    defaultMessage: 'Sum of forecast (incl. corrections)',
  },
  sumOrderStockNetValue: {
    id: `${scope}.sumorderstocknetvalue`,
    defaultMessage: 'Sum ordes stock',
  }
});