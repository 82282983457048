import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import ForecastDataPage from "./pages/ForecastDataPage";
import AddForecast from "./pages/AddForecast";
import ActualSalesPerMonthDashboard from "./features/forecastData/components/acualSalesPerMonthDashboard";
import  Home  from "./pages/Home";


const AppRoutes = [
  {
    
    element: <Home />
  },
  {
    path: '/counter',
    element: <Counter />
  },
  {
    path: '/fetch-data',
    element: <FetchData />
  },
  {
    path: '/forecastData',
    element: <ForecastDataPage />
  },
  {
    index: true,
    element:<Home />
  },
  {
    path: '/test',

    element:<ActualSalesPerMonthDashboard itemKey="5240" customerKey="649"/>
  },
  
];

export default AppRoutes;
