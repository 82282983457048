import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  language: 'sv', 
  languageFile:'../../translations/sv.json' 
};
 
export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setLanguageFile:(state, action) => {
      state.languageFile = action.payload;
    }

  },
});

export const { setLanguage, setLanguageFile } = languageSlice.actions;

export default languageSlice.reducer;