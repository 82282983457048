import React from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import axios from "axios";
import { loginRequest } from "./authConfig";



  const RequestInterceptor = ({children}) => {

    const {instance, accounts} = useMsal();
    const account = useAccount(accounts[0]);
    axios.interceptors.request.use(async config => {
    if(!account) {
        throw Error('No active account!');
    }
    const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account,
    });

    const bearer = `Bearer ${response.accessToken}`;
    config.headers['Authorization']  = bearer;
 
    
    return config;
    

    });
    return (
        <>
          {children}
        </>
      ); 
};
export default RequestInterceptor;