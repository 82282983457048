import axios from "axios";

export const getForecastData = async (customerKey, salesPersonKey, itemKey, dateKey, customerRating) => {
    try {
      //if(customerKey === '' && salesPersonKey === '' && itemKey === '' && dateKey === '') return Promise.resolve([]);
      const response = await axios.get(`/api/automatedforecasts/?companyKey=1&customerKey=${customerKey}&salesPersonKey=${salesPersonKey}&itemKey=${itemKey}&dateKey=${dateKey}&customerRating=${customerRating}`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

export const getCustomers = async () => {
    try {
      const response = await axios.get(`/api/customers/`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

  export const getAllCustomers = async () => {
    try {
      const response = await axios.get(`/api/customers/all`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

export const getSalesPersons = async () => {
    try {
      const response = await axios.get(`/api/salespersons/`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

export const getItems = async () => {
    try {
      const response = await axios.get(`/api/items/`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

  export const getAllItems = async () => {
    try {
      const response = await axios.get(`/api/items/dimension`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

  export const getAcutalSalesPerMonth = async (itemKey, customerKey) => {
    console.log("here");
    try {
      const response = await axios.get(`/api/ActualSalesPerMonth?itemKey=${itemKey}&customerKey=${customerKey}`);
      console.log(response.data);

     
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

export const getDateKeys = async () => {
    try {
      const response = await axios.get(`/api/datekeys/`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };
  
  export const getFinancialBudgets = async (dateKey) => {
      try {
        const response = await axios.get(`/api/financialbudgets/?dateKey=${dateKey}`);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    };

export const updateForcastQuantity = async (forecast) => {
    try {
      const response = await axios.patch(`/api/automatedforecasts/`, forecast);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

export const createForecast = async (forecast) => {
    try {
      const response = await axios.post(`/api/automatedforecasts/`, forecast);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
