import React, { useState, useEffect } from 'react'
import { Box, Button, FormControl } from '@mui/material'
import AxionControls from '../../../components/controls/AxionControls'
import { useMsal, useAccount } from "@azure/msal-react";
import { createForecast } from '../hooks/fetchers'
import { FormattedMessage } from 'react-intl'
import messages from './messages'
import { CollectionsOutlined } from '@mui/icons-material';

const AddForecast = (props) => {
    const { accounts } = useMsal();
    const account = useAccount(accounts[0]);
    const [forecastNetVolume, setForecastNetVolume] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    let dateKey = props.dateKey 
    let date = props.date

    const [forecast, setForecast] = useState({
        dateKey: dateKey,
        itemKey: "",
        customerKey: "",
        itemPrice: 0,
        correctedForecastQty: 0,
        updatedBy: account.username
    });

    useEffect(() => {
        if(forecast.correctedForecastQty && forecast.itemPrice  && forecast.correctedForecastQty > 0 && forecast.itemPrice > 0) {
            setForecastNetVolume(forecast.correctedForecastQty * forecast.itemPrice);
        }
        else {  
            setForecastNetVolume(0);
        }
    }, [forecast.correctedForecastQty, forecast.itemPrice]);

    function handleChange(event) {
        const {id, name, value, type, checked} = event.target
        
        setForecast(prevForecast => {
            return {
                ...prevForecast,
                [id]: type === "checkbox" ? checked : value
            }
        })
    }

    const transformItem = (items) => {
        let renamed = items.map((item) => ({id: item.key, label: item.code + ' - ' + item.name}));
        return renamed;
    }

    const handleCreate = () => {
        console.log(forecast);
        createForecast(forecast);
        props.setOpenPopup(false);
        props.forecasts.refetch();
    }   
    
    return (
        <Box sx={{ flexGrow: 1, 
            p: 1,
            m: 1, 
            width: 400,
            }}>
            { !dateKey && <div>Ange en månad i filtret för att kunna lägga till en prognos rad</div> }
            { dateKey && <form>
                <AxionControls.AxionTextBoxReadOnly 
                    sx={{ width: 400}}
                    label="Månad" 
                    value={date} />
                <FormControl sx={{ minWidth: 400, marginTop: 1}}>
                    <AxionControls.AxionAutoComplete
                        label={<FormattedMessage {...messages.itemNameColumnLabel} />}
                        value={selectedItem}
                    
                        onChange={(event, value) => {
                            setSelectedItem(value);
                            setForecast(prevForecast => {
                                return {
                                    ...prevForecast,
                                    itemKey: value.id
                                }
                            })}}
                        //options={items.data.map(({key, code}) => ({key: key, title: code }))}
                        options={transformItem(props.items)}
                        optionLabel="name"
                        optionKey="key"
                        />
                </FormControl>
                <FormControl sx={{ minWidth: 400, marginTop: 1}}>
                    <AxionControls.AxionAutoComplete
                        label={<FormattedMessage {...messages.customerNameColumnLabel} />}
                        value={selectedCustomer}
                    
                        onChange={(event, value) => {
                            setSelectedCustomer(value);
                            setForecast(prevForecast => {
                                return {
                                    ...prevForecast,
                                    customerKey: value.id
                                }
                            })}}
                        //options={items.data.map(({key, code}) => ({key: key, title: code }))}
                        options={transformItem(props.customers)}
                        optionLabel="name"
                        optionKey="key"
                        />
                </FormControl>
                <AxionControls.AxionTextBox 
                    sx={{ width: 400}}
                    type="number"
                    label="Pris" 
                    value={forecast.itemPrice} 
                    onChange={handleChange} 
                    name="itemPrice" />
                <AxionControls.AxionTextBox 
                    sx={{ width: 400}}
                    type="number"
                    label="Kvantitet" 
                    value={forecast.correctedForecastQty} 
                    onChange={handleChange} 
                    name="correctedForecastQty" />
                <AxionControls.AxionTextBoxReadOnly 
                    sx={{ width: 400}}
                    type="number"
                    label="Värde" 
                    value={forecastNetVolume} 
                    name="correctedForecastNetValue" />
                <AxionControls.AxionTextBox
                    sx={{ width: 400}}
                    label="Inlagd av" 
                    value={forecast.updatedBy} 
                    onChange={handleChange} 
                    name="updatedBy" />
                <Button 
                    variant="contained" 
                    sx={{
                        marginTop: 2,
                        }}
                    onClick={handleCreate}>
                    Create
                </Button>
            </form> }
        </Box>
    )
}

export default AddForecast