import { configureStore } from '@reduxjs/toolkit'
// import counterReducer from './counterReducer'
import languageReducer from '../context/languageProvider/reducer'

const store = configureStore({
  reducer: {
    language: languageReducer,
  },
});

export default store